<template>
  <div class="flex flex-col justify-center h-full">
    <!-- Table -->
    <div
      class="
        h-full
        w-full
        mx-auto
        bg-white
        shadow-lg
        rounded-sm
        border border-gray-200
      "
    >
      <header class="px-5 py-4 border-b border-gray-100">
        <div class="font-semibold text-gray-800">Staff with shortages</div>
      </header>

      <div class="overflow-x-auto p-3">
        <table class="table-auto w-full">
          <thead
            class="text-xs font-semibold uppercase text-gray-400 bg-gray-50"
          >
            <tr>
              <th class="p-2">
                <div class="font-semibold text-left">Staff Name</div>
              </th>

              <th class="p-2">
                <div class="font-semibold text-right">Total Shortage</div>
              </th>
            </tr>
          </thead>

          <tbody
            v-if="data.length > 0"
            class="text-sm divide-y divide-gray-100"
          >
            <!-- record 1 -->
            <tr v-for="(staff, i) in data" :key="i">
              <td class="p-2">
                <div class="font-medium text-gray-800">
                  {{ staff.first_name }} {{ staff.last_name }}
                </div>
              </td>

              <td class="p-2">
                <div class="text-right font-medium text-red-500">
                  GMD {{ formatPrice(staff.total_shortage) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- total amount -->
      <div
        class="
          flex
          justify-end
          font-bold
          space-x-4
          text-2xl
          border-t border-gray-100
          px-5
          py-4
        "
      >
        <div>Total</div>
        <div class="text-red-600">
          GMD <b>{{ formatPrice(totalAmount) }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    data: null,
  }),
  mounted() {
    this.loadData();
  },
  computed: {
    totalAmount: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(Number(val.total_shortage)); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
  },
  methods: {
    async loadData() {
      try {
        const { data } = await axios.get(`/list_shortages`);
        this.data = data.data;
        console.log(data);
      } catch (e) {
        console.log("Error", e);
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>